.adopt-wrapper{
  text-align:center;

  h2{
    color:#1cb87c;
    font-weight:bold;
  }
}
.how-to-adopt{

  img{
    height:150px;
  }
  label{
    margin-top:25px;
    color:#3399cc;
    font-weight:bold;
  }
}
.adoption-fees{

  label{
    color:#3399cc;
    font-weight:bold;
  }
}
.adopt-form{

  a{
    color:#3399cc;
    font-weight:bold;
  }
  p{
    margin-top:25px;
  }
}
