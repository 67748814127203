#HomeBanner {
  background: url("../images/home/Slide1_image.jpg");
  background-size: 100% auto;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-blend-mode: darken;
  transition: 2s;
}
.homepage-title {
  color: #fff;
  width: 100%;
  text-align: center;
  font-weight: normal;
}
.adopt-now-button {
  color: #fff;
  background: #1cb87c;
  font-weight: bold;
  margin-top: 50px;
  padding: 10px 40px;
}
.our-mission-box {
  padding: 25px;
  margin-top: -90px;
  background: rgba(48, 149, 202, 0.85);
  color: #ffffff;
  text-align: center;
  width: 970px;
  margin-left: auto;
  margin-right: auto;
}
.our-mission-box h2 {
  margin-bottom: 25px;
  font-size: 45px;
}
.our-mission-box p {
  font-size: 15px;
}
.donate-wrapper {
  text-align: center;
  margin-top: 50px;
}
.home-donate {
  font-size: 75px;
  color: #267399;
  font-weight: bold;
}
.heart-paw-image {
  width: 100px;
}
.donation-options img {
  max-width: 100%;
  height: 50px;
  padding-top: 10px;
}

@media screen and (max-width:991px) {
  .our-mission-box {
    width: 80%;
    margin-left: 10%;
  }
  .our-mission-box h2 {
    font-size: 32px;
  }
  .donation-options img {
    margin: 30px 0;
    height: 40px;
  }
  #HomeBanner {
    background: url("../images/home/htdr-dogs-3.png");
  }
}

@media screen and (max-width: 767px){
  #HomeBanner {
    background: url("../images/home/htdr-dogs-3.png");
  }
}
/* CSS specific to iOS devices */
@supports (-webkit-overflow-scrolling: touch) {
  #HomeBanner {
    //background-position: center top;
    //background-size: contain;
  }
}

