.dog-image{
  width: 100%;
}
.dog-title{
  font-size:20px;
}
.dog-status, .dog-age, .dog-bio{
  font-size:14px;
}
.dog-adoption-wrapper{
  min-height:150px;
  border-bottom:1px solid #ccc;
  margin-bottom:50px;
  margin-top:50px;
  padding-bottom:50px;
}
.available-dog-wrapper{
  min-height:100vh;
}
.dog-gallery-image{
  padding:0;
  border: 1px solid #fff;
  margin-top: 1px;
}

.available-dogs{
  margin-top:50px;

  h2{
    font-size: 45px;
    color:#39c;
    font-weight:bold;
  }
  a{
    font-weight:bold;
    color:#39c;
  }
}

