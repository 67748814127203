
/*Header*/
/*****************************************************************************************************/
#header {
  width: 100%;
  z-index: 99;
  height:150px;
  background:#3095ca;
}
.nav-item {
  padding: 5px 15px;

  a {
    color: #fff;
    text-decoration: none;
    font-weight: bold;

    &:hover {
      text-decoration: none;
      //border-bottom: 1px solid #fff;
    }
  }
}

#navbarNav{
  flex-direction:column;
}

.upper-nav-menu{
  display:block;
  text-align:right;
  width: 100%;
  padding:0 15px;
  margin-top:20px;
  margin-bottom:25px;

  a{
    color:#fff;
  }

  img{
    width: 25px;
    margin: -5px 5px 0 5px;
  }

  .header-donate-button{
    padding:10px 50px;
    background-color: #f56c54;
  }
}

.dropdown-menu{
  background: none;
  border:none;
  margin-left:-75%;
  background-color:#3095ca;
  -webkit-box-shadow: 0px 0px 25px -5px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 25px -5px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 25px -5px rgba(0,0,0,0.75);

  &.show{
    display:flex;
  }
  .dropdown-item{

    &:hover{
      color:#fff;
      background:none;
    }
  }
}
.nav-link{
  padding:0;
}
.mobile-nav-menu {
  display: none;
  position: fixed;
  right: 0;
  left: 50%;
  top: 0;
  bottom: 0;
  background: #3095ca;
  color: #fff;
  padding-top: 100px;
  z-index: 999;
  -webkit-box-shadow: -5px 0px 25px -5px rgba(0,0,0,0.75);
  -moz-box-shadow: -5px 0px 25px -5px rgba(0,0,0,0.75);
  box-shadow: -5px 0px 25px -5px rgba(0,0,0,0.75);

  .mobile-nav-item {
    padding: 15px 15px;
    font-size: 18px;
    font-weight: bold;

    .mobile-nav-sub-items{
      display:none;
      background: #267399;
      margin-top: 15px;
      margin-left: -15px;
      margin-right: -15px;

      .mobile-nav-item{
        padding:15px;
      }
    }
  }
}

.nav-dropdown-arrow{
  height:18px;
}

.mobile-button {
  z-index: 999;
  right: 25px;
  position: absolute;
  top: 50px;
  color: #fff;

  img {
    width: 30px;
  }
}
.mobile-close {
  display: none;
  z-index: 999;
  position: absolute;
  right: 25px;
  top: 50px;

  img {
    width: 25px;
  }
}
.navbar-brand {
  max-width: 50%;

  img {
    width: 75%;
    margin-top: 15px;
  }
}



/*Mobile Menu*/
/*****************************************************************************************************/
//.mobile-nav-menu{
//  display:none;
//  background-color: $clr_secondary;
//  position:fixed;
//  top:$header_height;
//  left:0;
//  right:0;
//
//  .mobile-nav-item{
//    text-align:center;
//    width:100%;
//    color:$clr_white;
//  }
//}
.mobile-close{
  display:none;
}
.mobile-button, .mobile-close{
  height:55px;
  width:55px;
  padding-top:10px;
}


.bottom-mobile-info {
  padding: 20px 15px;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.mobile-donate {
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f56c54;
  width: 100%;

  img {
    width: 25px;
  }
}
.mobile-phone {
  margin-bottom: 10px;
  margin-top: 10px;
  margin-left:10px;
}
.mobile-additional-links img {
  width: 25px;
  margin-left: 7px;
  margin-right: 7px;
}


@media screen and (max-width:991px){
  .navbar-brand{

    img{
      margin-top:25px;
      width:100%;
      max-height: 90px;
    }
  }
}
