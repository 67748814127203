.contact-wrapper{
  text-align:center;

  h2{
    color:#39c;
    font-weight:bold;
  }
}

.contact-info-image{
  width:35%;
}

.contact-address{
  text-align:right;
}

.contact-icons{
  text-align: left;

  img{
    width:25px;
  }
}
.contact-form-wrapper{

  input, textarea{
    width:100%;
  }
}

.contact-form{
  text-align:left;
}

.contact-form-image img{
  width:100%;
}

#formError{
  display:none;
}

.submit-button {
  text-align:right;

  .htdr-button {
    margin-top: 25px;
    background-color: #3095ca;
    color: #fff;
    padding:5px 40px;
    border-radius:0;
  }
}

@media screen and (max-width:991px){
  .contact-info-image{
    width:75%;
  }

  .contact-address{
    text-align:center;
  }

  .contact-icons{
    text-align:center;

    img{
      display:block;
      margin:0 auto;
      margin-top: 10px;
      margin-bottom:5px;
    }
  }
}
