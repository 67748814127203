
/*Fonts*/
/*****************************************************************************************************/
p{
  font-size:$fs_normal_text;
}
h2{
  font-size:$fs_h2;
}
h3{
  font-size:$fs_h3;
}
h4{
  font-size:$fs_h4;
}
h5{
  font-size:$fs_h5;
}
h6{
  font-size:$fs_h6;
}



/*General*/
/*****************************************************************************************************/
html{
  height:100%;
}

html, body{
  margin:0;
  padding:0;
}

body{
  width:100%;
  min-height:100%;
  position:relative;
  font-size:$fs_normal_text;
  font-family: 'century-gothic';
}

.flex{
  display:flex;
}

ul {
  list-style-type: none;
  padding: 0;

  li {
    padding: 0;
  }
}



/*Margins*/
/*****************************************************************************************************/
.mt-10{
  margin-top:10px;
}
.mt-25{
  margin-top:25px;
}
.mt-50{
  margin-top:50px;
}
.mt-100{
  margin-top:100px;
}
.m-n1{
  margin:-1px;
}

