

/*Footer*/
/*****************************************************************************************************/
footer {
  width: 100%;
  background: #3095ca;
  font-size: 15px;
  color: #fff;
  padding: 25px 0 0 0;
  margin-top:50px;
}
.powered-section {
  text-align: center;
  padding-bottom: 25px;
  border-bottom: 1px solid #fff;
}
.powered-section img {
  height: 75px;
}
.ht-footer {
  margin-top: 25px;
}
.footer-logo {
  width:100%;
  margin-bottom: 25px;
  min-width: 210px;
}
.footer-link {
  display: block;
  color: #fff;
  padding: 5px 0;
  min-width: 265px;
}
.footer-link img {
  width: 25px;
  margin-right: 15px;
}
.footer-address{
  text-align:right;
}
.footer-copyright{
  background: #267399;
  margin:25px 0 0 0;
  padding:25px;
  width: 100%;
  text-align: center;

  .footer-copyright-link{
    color: #fff;
  }
}


@media screen and (max-width:991px){
  .powered-section img {
    height: 30px;
  }
  .footer-logo{
    width:50%;
  }
  .ht-footer{
    text-align:center;

    .footer-address{
      text-align:center;
    }
  }

  .footer-links{
    display: flex;
    flex-direction: column; /* Ensures each link is treated as a column */
    gap: 5px; 
    margin-left:20%;
    align-items: center;
  }
    .footer-link {
      display: flex;
      align-items: center; /* Vertically centers the image and text */
      gap: 10px; /* Adds space between the image and the text */
  }
  
  .footer-link img {
      flex-shrink: 0; /* Prevents the image from shrinking */
  }
}
