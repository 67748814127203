.about-wrapper{
  text-align:center;

  h2{
    color:#1cb87c;
    font-weight:bold;
  }
}

.donate-amounts{

  a{
    color:#000;

    &:hover{
      color:#000;
    }
  }

  img{
    width:50%;
    margin-bottom:10px;
  }
}
.donation-options .row{
  flex-wrap: nowrap;
  justify-content: center;
}
.about-pictures{

  img{
    max-width:100%;
  }
}

@media screen and (max-width:991px){
  .donate-amounts > div{
    margin-top:10px;
    margin-bottom:10px;
  }

  .about-pictures >div:nth-child(2){
    display:none;
  }

  .donation-options .row{
    flex-wrap: wrap;
  }
}
